<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <div class="d-flex align-items-center pr-2 mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >2 Days Ago</span
          >
          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <inline-svg
                src="media/svg/misc/015-telegram.svg"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>
        <!--end::Info-->
        <a
          href="#"
          class="text-dark font-weight-bolder text-hover-primary font-size-h4"
          >Craft - ReactJS Admin <br />Theme</a
        >
        <!--begin::Desc-->
        <p class="text-dark-50 font-weight-normal font-size-lg mt-6">
          Craft uses the latest and greatest frameworks <br />with ReactJS for
          complete modernization and <br />future proofing your business
          operations <br />and sales opportunities
        </p>
        <!--end::Desc-->
      </div>
      <!--begin::Team-->
      <div class="d-flex align-items-center">
        <!--begin::Pic-->
        <a href="#" class="symbol symbol-45 symbol-light mr-3">
          <div class="symbol-label">
            <inline-svg
              src="media/svg/avatars/001-boy.svg"
              class="h-75 align-self-end"
              alt=""
            />
          </div>
        </a>
        <!--end::Pic-->
        <!--begin::Pic-->
        <a href="#" class="symbol symbol-45 symbol-light mr-3">
          <div class="symbol-label">
            <inline-svg
              src="media/svg/avatars/028-girl-16.svg"
              class="h-75 align-self-end"
              alt=""
            />
          </div>
        </a>
        <!--end::Pic-->
        <!--begin: Pic-->
        <a href="#" class="symbol symbol-45 symbol-light mr-3">
          <div class="symbol-label">
            <inline-svg
              src="media/svg/avatars/024-boy-9.svg"
              class="h-75 align-self-end"
              alt=""
            />
          </div>
        </a>
        <!--end::Pic-->
        <!--begin::Pic-->
        <a href="#" class="symbol symbol-45 symbol-light">
          <div class="symbol-label">
            <inline-svg
              src="media/svg/avatars/005-girl-2.svg"
              class="h-75 align-self-end"
              alt=""
            />
          </div>
        </a>
        <!--end::Pic-->
      </div>
      <!--end::Team-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "Widget12"
};
</script>
